import axios, { AxiosResponse } from "axios";
import { FileCheck, LockOpen, UserCircle, Users } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useUser } from "../../contexts/UserContext";
import KYCVerificationIcon from "../../icons/KYCVerificationIcon";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { MetricCard } from "../ui/metric-card";

export const DashboardOverview = () => {
    const { user, refreshToken } = useUser();
    const [totalUses, setTotalUses] = useState(0);
    const [ageVerification, setAgeVerification] = useState(0);
    const [failedAgeVerifications, setFailedAgeVerifications] = useState(0);
    const [idVerifications, setIdVerifications] = useState(0);
    const [spoofs, setSpoofs] = useState(0);
    const [faceRecognition, setFaceRecognition] = useState(0);
    const [failedFaceRecognitions, setFailedFaceRecognitions] = useState(0);
    const [kycVerification, setKycVerification] = useState(0);
    const [combinedData, setCombinedData] = useState();
    const [ageDistribution, setAgeDistribution] = useState<any>();
    const [isFrSubActive, setIsFrSubActive] = useState<boolean>();
    const [isAgeSubActive, setIsAgeSubActive] = useState<boolean>();
    const [isKycSubActive, setIsKycSubActive] = useState<boolean>();
    const [dateRange, setDateRange] = useState<string>();
    const startDate = useRef<HTMLInputElement>();
    const endDate = useRef<HTMLInputElement>();
    var globalAgeResponse: any[] = [];
    var globalFaceResponse: any[] = [];
    var globalKycResponse: any[] = [];

    useEffect(() => {
        //Default date range
        setDateRange("Today");

        const fetchClientData = async () => {
            try {
                if (user) {
                    let res = JSON.parse(user.frSubscription).IsActive.toLowerCase() == "true";
                    setIsFrSubActive(res);
                    let res2 = JSON.parse(user.ageSubscription).IsActive.toLowerCase() == "true";
                    setIsAgeSubActive(res2);
                    let res3 = JSON.parse(user.kycSubscription).IsActive.toLowerCase() == "true";
                    setIsKycSubActive(res3);
                }

                //AGE
                if (isAgeSubActive) {
                    await setAgeRelatedStats();
                }
                //FACE
                if (isFrSubActive) {
                    await setFaceRelatedStats();
                }

                //KYC
                if (isKycSubActive) {
                    await setKycRelatedStats();
                }

                if (isFrSubActive || isAgeSubActive || isKycSubActive) {
                    await handleDateRange();
                }
            }
            catch (error) {
                console.error("Error fetching client data:", error);
            }
        };

        if (user?.expirationTime) {
            let time = user?.expirationTime * 1000;

            if (time < Date.now()) {
                refreshToken().then(fetchClientData);
                return;
            }
        }
        fetchClientData();
    }, [isFrSubActive, isAgeSubActive]);

    const handleDateRange = async (event?) => {
        let dateRange = "";

        if (event == "Custom") {
            dateRange = "Custom";
        }
        else {
            startDate.current.value = "";
            endDate.current.value = "";
            dateRange = event?.target.value ?? "Today";
        }
        setDateRange(dateRange);

        if (globalAgeResponse.length === 0) {
            globalAgeResponse = (await getClientData("AGE")).data;
        }

        if (globalFaceResponse.length === 0) {
            globalFaceResponse = (await getClientData("FR")).data;
        }

        if (globalKycResponse.length === 0) {
            globalKycResponse = (await getClientData("KYC")).data;
        }

        let ageDataToAdd = [];
        let frDataToAdd = [];
        let kycDataToAdd = [];

        if (dateRange == "Today") {
            const todayDate = new Date().toDateString();

            for (let i = 0; i < globalAgeResponse.length; i++) {
                if (new Date(globalAgeResponse[i].created_at).toDateString() === todayDate) {
                    ageDataToAdd.push(globalAgeResponse[i]);
                }
            }

            for (let i = 0; i < globalFaceResponse.length; i++) {
                if (new Date(globalFaceResponse[i].created_at).toDateString() === todayDate) {
                    frDataToAdd.push(globalFaceResponse[i]);
                }
            }

            for (let i = 0; i < globalKycResponse.length; i++) {
                if (new Date(globalKycResponse[i].created_at).toDateString() === todayDate) {
                    kycDataToAdd.push(globalKycResponse[i]);
                }
            }
        }
        else if (dateRange == "Last 7 Days") {
            const lastWeekDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

            for (let i = 0; i < globalAgeResponse.length; i++) {
                if (new Date(globalAgeResponse[i].created_at) >= lastWeekDate) {
                    ageDataToAdd.push(globalAgeResponse[i]);
                }
            }

            for (let i = 0; i < globalFaceResponse.length; i++) {
                if (new Date(globalFaceResponse[i].created_at) > lastWeekDate) {
                    frDataToAdd.push(globalFaceResponse[i]);
                }
            }

            for (let i = 0; i < globalKycResponse.length; i++) {
                if (new Date(globalKycResponse[i].created_at) > lastWeekDate) {
                    kycDataToAdd.push(globalKycResponse[i]);
                }
            }
        }
        else if (dateRange == "Last 30 Days") {
            const lastMonthDate = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);

            for (let i = 0; i < globalAgeResponse.length; i++) {
                if (new Date(globalAgeResponse[i].created_at) >= lastMonthDate) {
                    ageDataToAdd.push(globalAgeResponse[i]);
                }
            }

            for (let i = 0; i < globalFaceResponse.length; i++) {
                if (new Date(globalFaceResponse[i].created_at) > lastMonthDate) {
                    frDataToAdd.push(globalFaceResponse[i]);
                }
            }

            for (let i = 0; i < globalKycResponse.length; i++) {
                if (new Date(globalKycResponse[i].created_at) > lastMonthDate) {
                    kycDataToAdd.push(globalKycResponse[i]);
                }
            }
        }
        else if (dateRange == "This Month") {
            const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

            for (let i = 0; i < globalAgeResponse.length; i++) {
                if (new Date(globalAgeResponse[i].created_at) >= startOfMonth) {
                    ageDataToAdd.push(globalAgeResponse[i]);
                }
            }

            for (let i = 0; i < globalFaceResponse.length; i++) {
                if (new Date(globalFaceResponse[i].created_at) >= startOfMonth) {
                    frDataToAdd.push(globalFaceResponse[i]);
                }
            }

            for (let i = 0; i < globalKycResponse.length; i++) {
                if (new Date(globalKycResponse[i].created_at) >= startOfMonth) {
                    kycDataToAdd.push(globalKycResponse[i]);
                }
            }
        }
        else if (dateRange == "Custom") {
            //custom dates
            if (startDate.current && endDate.current) {
                for (let i = 0; i < globalAgeResponse.length; i++) {
                    if (new Date(globalAgeResponse[i].created_at) >= startDate.current.valueAsDate && new Date(globalAgeResponse[i].created_at) <= endDate.current.valueAsDate) {
                        ageDataToAdd.push(globalAgeResponse[i]);
                    }
                }

                for (let i = 0; i < globalFaceResponse.length; i++) {
                    if (new Date(globalFaceResponse[i].created_at) >= startDate.current.valueAsDate && new Date(globalFaceResponse[i].created_at) <= endDate.current.valueAsDate) {
                        frDataToAdd.push(globalFaceResponse[i]);
                    }
                }

                for (let i = 0; i < globalKycResponse.length; i++) {
                    if (new Date(globalKycResponse[i].created_at) >= startDate.current.valueAsDate && new Date(globalKycResponse[i].created_at) <= endDate.current.valueAsDate) {
                        kycDataToAdd.push(globalKycResponse[i]);
                    }
                }
            }
        }

        //set age
        let successfulAges = ageDataToAdd.filter(x => x.status == "SUCCESS");
        let failedAges = ageDataToAdd.filter(x => x.status != "SUCCESS");
        let spoofs = ageDataToAdd.filter(x => x.is_spoof == 1);
        let idVerifs = ageDataToAdd.filter(x => x.age_verification != null && x.age_verification != "N/A");
        let totalAgeData = ageDataToAdd.length;

        setAgeVerification(successfulAges.length);
        setFailedAgeVerifications(failedAges.length);
        setSpoofs(spoofs.length);
        setIdVerifications(idVerifs.length);

        //set KYC
        setKycVerification(kycDataToAdd.length);

        const genderData = ageDataToAdd.reduce((acc, curr) => {
            if (curr.age) {
                const gender = curr.gender;
                acc[gender] = (acc[gender] || 0) + 1;
            }
            return acc;
        }, {});

        let data: any = [];

        const allCategories = [...new Set([...Object.keys(genderData)])];

        allCategories.forEach((category) => {
            const genderCount = genderData[category] || 0;
            data.push({
                name: category[0].toUpperCase() + category.slice(1),
                gender: genderCount,
            });
        });

        setCombinedData(data);

        // GRAPHS
        const aggregatedData = ageDataToAdd.reduce((acc: any, { age }: any) => {
            const range = getAgeRange(age);

            if (range) {
                if (!acc[range]) acc[range] = 0;
                acc[range]++;
            }
            return acc;
        }, {});

        const ageDistribution = Object.keys(aggregatedData).map((range) => ({
            ageRange: range,
            count: aggregatedData[range],
        }));

        setAgeDistribution(ageDistribution);

        //set fr
        let successfulFrs = frDataToAdd.filter(x => x.face_search.face_search.is_face_found == true);
        let failedFrs = frDataToAdd.filter(x => x.face_search.face_search.is_face_found == false);
        let totalFaceData = frDataToAdd.length;
        setFaceRecognition(successfulFrs.length);
        setFailedFaceRecognitions(failedFrs.length);

        setTotalUses(totalAgeData + totalFaceData);
    }

    const getKeyByType = (type: string) => {
        if (type == "FR") {
            return "&key=face_search";
        }
        else if (type == "AGE") {
            return "";
        }
        else if (type == "KYC") {
            return "&key=kyc";
        }
        else if (type == "SMILE") {
            return "&key=smilenpay";
        }
    }

    const getClientData = async (type: string) => {
        return await axios.get<any[]>(
            `${process.env.REACT_APP_AI_API_URL}/get-client-data?client_id=${user?.clientId}${getKeyByType(type)}`
        );
    }

    const setFaceRelatedStats = async () => {
        let faceResponse: AxiosResponse<any[], any>;
        faceResponse = await getClientData("FR");
        globalFaceResponse = faceResponse.data;
    }

    const setAgeRelatedStats = async () => {
        let ageResponse: AxiosResponse<any[], any>;
        ageResponse = await getClientData("AGE");
        globalAgeResponse = ageResponse.data;
    }

    const setKycRelatedStats = async () => {
        let kycResponse: AxiosResponse<any[], any>;
        kycResponse = await getClientData("KYC");
        globalKycResponse = kycResponse.data;
    }

    const getAgeRange = (age: any) => {
        if (age >= 18 && age < 26) return "18-25";
        if (age >= 26 && age < 36) return "26-35";
        if (age >= 36 && age < 46) return "36-45";
        if (age >= 46 && age < 56) return "46-55";
        if (age >= 56 && age < 66) return "56-65";
    };

    const filterByDate = () => {
        let start = startDate.current.valueAsDate;
        let end = endDate.current.valueAsDate;

        if (start) {
            if (end && start > end) {
                endDate.current.value = startDate.current.value;
            }
            endDate.current.min = startDate.current.value;
        }

        if (start && end) {
            handleDateRange("Custom");
        }
    }

    const DistributionChart = ({ data }: any) => {
        return (
            <div className="h-full w-full">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={800} height={400} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="gender" fill="#1c5257" name="Gender Distribution" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    const AgeDistributionChart = ({ ageDistribution }: any) => {
        return (
            <div className="h-full w-full">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={ageDistribution}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="ageRange" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#000000" name="No. of Users in this Range" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    return (
        <div className="flex-1 space-y-4 fade-in">
            <div className="flex items-center justify-between space-y-2">
                <h2 className="text-3xl font-bold tracking-tight">Overview</h2>
                <div className="flex items-center space-x-2">
                    <span className="flex h-2 w-2 rounded-full bg-green-500"></span>
                    <span className="text-sm text-muted-foreground">Active</span>
                </div>
            </div>

            <div id="range-picker" className="row align-items-center g-3">
                {/* Dropdown for Date Range */}
                <div className="col-lg-3 col-12 ps-0 pe-0 mt-0">
                    <select
                        className="form-select"
                        onChange={(e) => handleDateRange(e)}
                        value={dateRange}
                    >
                        <option value=""></option>
                        <option value="Today">Today</option>
                        <option value="Last 7 Days">Last 7 Days</option>
                        <option value="Last 30 Days">Last 30 Days</option>
                        <option value="This Month">This Month</option>
                    </select>
                </div>

                {/* Label for "Or Filter By Date" */}
                <div className="col-lg-2 col-12 ps-0 pe-0 text-center mt-2 mb-2">
                    <p className="mb-0">Or Filter By Date</p>
                </div>

                {/* Start Date Input */}
                <div className="col-lg-3 col-12 ps-0 pe-0 mt-0">
                    <input
                        className="form-control"
                        ref={startDate}
                        onChange={filterByDate}
                        type="date"
                        id="startDate"
                    />
                </div>

                {/* "To" Separator */}
                <div className="col-lg-1 col-12 ps-0 pe-0 text-center mt-2 mb-2">
                    <p className="mb-0">to</p>
                </div>

                {/* End Date Input */}
                <div className="col-lg-3 col-12 ps-0 pe-0 mt-0">
                    <input
                        className="form-control"
                        ref={endDate}
                        onChange={filterByDate}
                        type="date"
                        id="endDate"
                        placeholder="Choose your end date"
                    />
                </div>
            </div>

            {/* <p className="text-sm mb-0">Showing: {dateRange} {period ? `, ${period}` : ''}</p> */}

            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                <MetricCard
                    title="Total Uses"
                    value={[totalUses.toString()]}
                    icon={<Users className="h-4 w-4 text-muted-foreground" />}
                    isSubActive={isFrSubActive && isAgeSubActive}
                />
                <MetricCard
                    title="Age Verification"
                    value={[ageVerification.toString(), failedAgeVerifications.toString(), idVerifications.toString(), spoofs.toString()]}
                    icon={<FileCheck className="h-4 w-4 text-muted-foreground" />}
                    isSubActive={isAgeSubActive}
                />
                <MetricCard
                    title="Face Recognition"
                    value={[faceRecognition.toString(), failedFaceRecognitions.toString()]}
                    icon={<UserCircle className="h-4 w-4 text-muted-foreground" />}
                    isSubActive={isFrSubActive}
                />
                <MetricCard
                    title="KYC Verification"
                    value={[kycVerification.toString()]}
                    icon={<div className="h-4 w-4 text-muted-foreground"><KYCVerificationIcon /></div>}
                    isSubActive={isKycSubActive}
                />
            </div>

            <div className="row gy-2 mb-4">
                <div className="col-md-6">
                    <Card>
                        <CardHeader>
                            <CardTitle>Gender Distribution</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="h-[300px]">
                                {isAgeSubActive &&
                                    <DistributionChart data={combinedData} />
                                }
                                {!isAgeSubActive &&
                                    <h1 className="d-flex flex-column justify-content-center h-100 align-items-center"><LockOpen className="mb-3" /> Subscribe To View Chart Data</h1>
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div className="col-md-6">
                    <Card>
                        <CardHeader>
                            <CardTitle>Age Distribution</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="h-[300px]">
                                {isAgeSubActive &&
                                    <AgeDistributionChart ageDistribution={ageDistribution} />
                                }
                                {!isAgeSubActive &&
                                    <h1 className="d-flex flex-column justify-content-center h-100 align-items-center"><LockOpen className="mb-3" /> Subscribe To View Chart Data</h1>
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}; 