import CaretLeft from "../../icons/CaretLeft";

interface IFormInput {
    id?: number;
    userId?: number;
    language?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    nationality: string;
    gender: string;
    facePath?: string;
    dateOfBirth: Date;
    documentType: string;
    documentFrontPath: string;
    documentBackPath: string;
    extractedInformation: string;
    country_name?: string;
    document_number?: string;
    dob?: string;
    issue_date?: string;
}

type ViewKycProps = {
    setShowKycData: React.Dispatch<React.SetStateAction<boolean>>
    kycData: IFormInput
}

function base64SizeInMB(base64String) {
    const base64Data = base64String.replace(/^data:image\/[a-z]+;base64,/, "");

    const byteSize = (base64Data.length * 3) / 4;

    const sizeInMB = byteSize / (1024 * 1024);

    return sizeInMB;
}

export const ViewKycData = ({ setShowKycData, kycData }: ViewKycProps) => {
    var extractedInfo = JSON.parse(kycData.extractedInformation);

    return (
        <div className="container" id="viewKycData">
            <div className="row justify-content-center w-100">
                <div className="col-md-12 ps-0">
                    <div className="d-flex align-items-center" style={{ width: "fit-content", cursor: "pointer" }} onClick={() => setShowKycData(false)} >
                        <div style={{ background: "white", width: "40px", borderRadius: "50%", padding: "10px" }}>
                            <CaretLeft width={"20"} height={"20"} />
                        </div>
                        <span className="ps-2">Go Back</span>
                    </div>
                </div>

                {extractedInfo ?
                    <>
                        <div className="kycDataCard row mt-4 mb-4 align-items-center">
                            <div className="col-xl-3 col-12 d-flex flex-column align-items-center">
                                <img className="rounded-circle img-responsive mb-2 pulse-border" style={{ width: "150px", height: "150px", objectFit: "cover", border: "3px solid #78ffb2", transform: "scaleX(-1)" }} src={kycData.facePath} />
                                <span className="mt-2">{kycData.firstName + " " + kycData.lastName}</span>
                            </div>
                            <div className="col-xl-9 col-12">
                                <div className="row justify-content-between align-items-start mt-lg-4 mt-0">
                                    <div className="col-lg-2 text-center text-lg-start mt-4 mt-lg-0"><p className="viewKycDataTitle">Document No.</p><p>{extractedInfo?.document_number}</p></div>
                                    <div className="col-lg-2 text-center text-lg-start mt-4 mt-lg-0"><p className="viewKycDataTitle">Issued Date</p><p>{extractedInfo?.issue_date}</p></div>
                                    <div className="col-lg-2 text-center text-lg-start mt-4 mt-lg-0">
                                        <p className="viewKycDataTitle">Expiry Date</p>
                                        <p>{extractedInfo?.expiry_date}</p>
                                    </div>
                                    <div className="col-lg-2 text-center text-lg-start mt-4 mt-lg-0"><p className="viewKycDataTitle">Country</p><p>{extractedInfo?.country_name}</p></div>
                                    <div className="col-lg-2 text-center text-lg-start mt-4 mt-lg-0"><p className="viewKycDataTitle">Date Of Birth</p><p>{extractedInfo?.dob}</p></div>
                                    <div className="d-flex flex-column flex-lg-row">
                                        <div className="col-lg-3 text-center text-lg-start d-lg-block d-flex justify-content-center mt-4">
                                            <div style={{ width: "fit-content" }}>
                                                <p className="viewKycDataTitle" style={{ marginBottom: "1rem" }}>Type Of Document</p>
                                                <span className="lightGreenBg w-100 viewKycDataButton">{extractedInfo?.documentType}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 text-center text-lg-start d-lg-block d-flex justify-content-center mt-4">
                                            <div style={{ width: "fit-content" }}>
                                                <p className="viewKycDataTitle" style={{ marginBottom: "1rem" }}>Status</p>
                                                <span className="lightGreenBg w-100 viewKycDataButton">Liveness</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 text-center text-lg-start d-lg-block d-flex justify-content-center mt-4">
                                            <div style={{ width: "fit-content" }}>
                                                <p className="viewKycDataTitle" style={{ marginBottom: "1rem" }}>Face</p>
                                                <span className="lightGreenBg w-100 viewKycDataButton">Match</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h1 className="text-xl font-bold tracking-tight mb-4">Document Data</h1>
                        <div className="kycDataCard row" style={{ padding: "20px 40px" }}>
                            <div className="col-md-12 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row">
                                    <div className="d-flex align-items-center">
                                        <img src={kycData.documentFrontPath} style={{ objectFit: "cover" }} className="docStyle" />
                                        <div className="d-flex flex-column ms-3">
                                            <span>Front Document</span>
                                            <span className="viewKycDataTitle mt-2">{base64SizeInMB(kycData.documentFrontPath).toFixed(2)} MB</span>
                                        </div>
                                    </div>
                                    <a href={kycData.documentFrontPath} className="cursor-pointer mt-3 mt-md-0" download style={{ textDecoration: "underline" }}>Download</a>
                                </div>
                            </div>
                            {kycData.documentBackPath && <div className="col-md-12 d-flex align-items-center mt-3">
                                <div className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row">
                                    <div className="d-flex align-items-center">
                                        <img src={kycData.documentBackPath} className="docStyle" />
                                        <div className="d-flex flex-column ms-3">
                                            <span>Back Document</span>
                                            <span className="viewKycDataTitle mt-2">{base64SizeInMB(kycData.documentBackPath).toFixed(2)} MB</span>
                                        </div>
                                    </div>
                                    <a href={kycData.documentBackPath} className="cursor-pointer mt-3 mt-md-0" download style={{ textDecoration: "underline" }}>Download</a>
                                </div>
                            </div>
                            }
                        </div>
                    </>
                    : <h1>It appears we couldn't extract information...</h1>}
            </div>
        </div>
    )
}