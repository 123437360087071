import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { formatDateTime } from "../../lib/utils";
import { smileandpayService } from "../../services/smileandpay.service";
import { ContactModal } from "../ContactModal";
import { DataTable } from "../DataTable";
import { Card, CardContent } from "../ui/card";
import { IntegrationSteps } from "./IntegrationSteps";

interface IFormInput {

}

export const SmileAndPay = () => {
    const { user } = useUser();
    let expiresOn = "";
    let subscription = null;
    let smileShouldShow = false;
    const columns = [
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "Action Time",
            accessorKey: "time_logs",
        },
        // {
        //     header: "Payment Details",
        //     accessorKey: "payment_details",
        // },
    ];
    const [spData, setSpData] = useState<IFormInput[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [answerFromIframe, setAnswerFromIframe] = useState();

    const handleMessage = (event) => {
        const iframe = document.getElementById('smile-iframe') as HTMLIFrameElement;

        if (iframe && event.source === iframe.contentWindow) {
            if (event.data && event.data.response) {
                setAnswerFromIframe(event.data.response);
            }
        }
    };

    function parseDate(dateString: string) {
        const [day, month, yearTime] = dateString.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute] = time.split(':');

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    const fetchHistory = async () => {
        try {
            const clientId = JSON.parse(localStorage.getItem("user")).clientId;
            const res = await smileandpayService.getHistory(clientId);

            if (res.length > 0) {
                const matchedFacesMetadata = res.flatMap(item => {
                    const sortedFaces = item.face_search?.face_search?.matched_faces
                        ?.map(face => ({
                            ...face.metadata,
                            time_logs: formatDateTime(item.face_search.time_logs.start + "Z")
                        }))
                        .sort((a, b) => new Date(b.enrollment_time ?? 0).getTime() - new Date(a.enrollment_time ?? 0).getTime());

                    return sortedFaces?.[0] ? [sortedFaces[0]] : [];
                });

                const sortedData = matchedFacesMetadata.sort((a, b) => parseDate(b.time_logs).getTime() - parseDate(a.time_logs).getTime()) || [];
                setSpData(sortedData);
            }
            else {
                setSpData([]);
            }
        }
        catch (err) {
            console.log("Error fetching smile and pay history:", err);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchHistory();

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [answerFromIframe]);

    if (user) {
        subscription = JSON.parse(user != null ? user.smileAndPaySubscription : "");

        if (subscription.IsActive.toLowerCase() == "true" && new Date(subscription.Expires) > new Date()) {
            smileShouldShow = true;
        }
        expiresOn = new Date(subscription.Expires).toLocaleString();
    }

    return (
        <div className="space-y-4 fade-in h-100">
            <div className="d-flex flex-column flex-sm-row gap-4 justify-between">
                <h2 className="text-3xl font-bold tracking-tight">
                    {subscription.Type == "Demo" ? "Smile & Pay Demo" : "Smile & Pay - " + subscription.Type}
                </h2>
                <div className="d-flex align-items-center">
                    {!smileShouldShow &&
                        <h1 className="font-bold mr-2">Expired On:</h1>
                    }
                    {smileShouldShow &&
                        <h1 className="font-bold mr-2">Expires On:</h1>
                    }
                    {expiresOn.split(",")[0]}
                </div>
            </div>
            <div className="rounded-xl bg-card text-card-foreground"><div className="p-0 border rounded-lg shadow-none"></div></div>
            {smileShouldShow && subscription.Type == "Demo" &&
                <iframe id="smile-iframe" src={`https://smileandpay.voltox.ai/?cId=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100%" }} allow="camera" allowFullScreen>
                </iframe>
                // <iframe id="smile-iframe" src={`http://localhost:5173/?cId=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100%" }} allow="camera" allowFullScreen>
                // </iframe>
            }
            {(smileShouldShow && subscription.Type != "Demo") &&
                <IntegrationSteps type="SmileAndPay" />
            }
            {!smileShouldShow && (subscription.IsActive.toLowerCase() != "true" || new Date(subscription.Expires) < new Date()) &&
                <div className="text-center">
                    <h1 className="text-center mt-5 mb-4">
                        Seems like your subscription has expired or has been deactivated.
                        <br /><br />Please contact support below.
                    </h1>
                    <ContactModal type={"SAP"} user={user} buttonText={"Request Access"} />
                </div>
            }
            <br />

            {smileShouldShow &&
                <div className="flex items-center justify-between">
                    <h2 className="text-3xl font-bold tracking-tight">
                        Smile & Pay User History
                    </h2>
                </div>
            }

            {smileShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                    </CardContent>
                </Card>
            }

            {smileShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                        {spData == null &&
                            <div className="text-center text-gray-500 p-4">No history found</div>
                        }
                        <DataTable
                            data={spData}
                            columns={columns}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </Card>
            }
        </div>
    )
}