import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { formatDateTime } from "../../lib/utils";
import { kycService } from "../../services/kyc.service";
import { ContactModal } from "../ContactModal";
import { DataTable } from "../DataTable";
import { Card, CardContent } from "../ui/card";
import { IntegrationSteps } from "./IntegrationSteps";
import { ViewKycData } from "./ViewKycData";

interface IFormInput {
    id?: number;
    userId?: number;
    language?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    nationality: string;
    gender: string;
    facePath?: string;
    dateOfBirth: Date;
    documentType: string;
    documentFrontPath: string;
    documentBackPath: string;
    extractedInformation: string;
    country_name?:string;
    document_number?:string;
    dob?:string;
    issue_date?:string;
}

interface DefaultResponse {
    flag: boolean;
    data: any;
    message: string;
}

export const KYCVerification = () => {
    const { user } = useUser();
    let expiresOn = "";
    let subscription = null;
    let kycShouldShow = false;
    const [answerFromIframe, setAnswerFromIframe] = useState();
    const [showKycData, setShowKycData] = useState(false);
    const [currentlyViewing, setCurrentlyViewing] = useState(null);

    if (user) {
        subscription = JSON.parse(user != null ? user.kycSubscription : "");

        if (subscription.IsActive.toLowerCase() == "true" && new Date(subscription.Expires) > new Date()) {
            kycShouldShow = true;
        }
        expiresOn = new Date(subscription.Expires).toLocaleString();
    }

    //kyc data
    const [kycData, setKycData] = useState<IFormInput[]>([]);
    const columns = [
        {
            header: "First Name",
            accessorKey: "firstName",
        },
        {
            header: "Last Name",
            accessorKey: "lastName",
        },
        {
            header: "Document Type",
            accessorKey: "documentType",
        },
        {
            header: "Date",
            accessorKey: "insertDateTime",
        },
        {
            header: "Action",
            accessorKey: "id",
            cell: (e) => {
                return <button type="button"
                    onClick={() => viewKycData(e.getValue())}
                    className="btn greenBg cursor-pointer">
                    View
                </button>
            }
        }
    ];
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchKycHistory();

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [answerFromIframe])

    const handleMessage = (event) => {
        const iframe = document.getElementById('kyc-iframe') as HTMLIFrameElement;

        if (iframe && event.source === iframe.contentWindow) {
            if (event.data && event.data.response) {
                setAnswerFromIframe(event.data.response);
            }
        }
    };

    function viewKycData(e) {
        setCurrentlyViewing(e);
        setShowKycData(true);
    }

    const fetchKycHistory = async () => {
        try {
            const clientId = JSON.parse(localStorage.getItem("user")).clientId;
            const res: DefaultResponse = await kycService.getKycHistory(clientId);

            res.data.forEach((element) => {
                element.insertDateTime = formatDateTime(element.insertDateTime + "Z");
            });

            const sortedData = res.data.sort((a, b) => {
                const dateA = parseDate(a.insertDateTime) as any;
                const dateB = parseDate(b.insertDateTime) as any;

                return dateB - dateA;
            });

            if (res.flag) {
                setKycData(sortedData);
            }
        } catch (err) {
            console.log("Error fetching kyc history:", err);
        } finally {
            setIsLoading(false);
        }
    };

    function parseDate(dateString: string) {
        const [day, month, yearTime] = dateString.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute] = time.split(':');

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    return (
        <div className="space-y-4 fade-in h-100">
            {!showKycData ? <>
                <div className="d-flex flex-column flex-sm-row gap-4 justify-between">
                    <h2 className="text-3xl font-bold tracking-tight">
                        {subscription.Type == "Demo" ? "KYC Verification Demo" : "KYC Verification - " + subscription.Type}
                    </h2>
                    <div className="d-flex align-items-center">
                        {!kycShouldShow &&
                            <h1 className="font-bold mr-2">Expired On:</h1>
                        }
                        {kycShouldShow &&
                            <h1 className="font-bold mr-2">Expires On:</h1>
                        }
                        {expiresOn.split(",")[0]}
                    </div>
                </div>
                <div className="rounded-xl bg-card text-card-foreground"><div className="p-0 border rounded-lg shadow-none"></div></div>
                {kycShouldShow && subscription.Type == "Demo" &&
                    <iframe id="kyc-iframe" src={`https://kyc.voltox.ai/?cId=${encodeURIComponent(user?.clientId ?? "")}&uId=${user?.userId}`} style={{ width: "100%", height: "100vh" }} allow="camera" allowFullScreen>
                    </iframe>
                    //<iframe id="kyc-iframe" src={`https://localhost:5173/?cId=${encodeURIComponent(user?.clientId ?? "")}&uId=${user?.userId}`} style={{ width: "100%", height: "100%" }} allow="camera" allowFullScreen>
                    //</iframe>
                }
                {(kycShouldShow && subscription.Type != "Demo") &&
                    <IntegrationSteps type="Kyc" />
                }
                {!kycShouldShow && (subscription.IsActive.toLowerCase() != "true" || new Date(subscription.Expires) < new Date()) &&
                    <div className="text-center">
                        <h1 className="text-center mt-5 mb-4">
                            Seems like your subscription has expired or has been deactivated.
                            <br /><br />Please contact support below.
                        </h1>
                        <ContactModal user={user} type={"KYC"} buttonText={"Request Access"} />
                    </div>
                }
                <br />
                <br />
                <br />

                {/* {frShouldShow && */}
                <div className="flex items-center justify-between">
                    <h2 className="text-3xl font-bold tracking-tight">
                        KYC Verification History
                    </h2>
                </div>
                {/* } */}

                {/* {frShouldShow && */}
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                    </CardContent>
                </Card>
                {/* } */}

                {/* {frShouldShow && */}
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                        {kycData == null &&
                            <div className="text-center text-gray-500 p-4">No KYC history found</div>
                        }
                        <DataTable
                            data={kycData.sort((a: any, b: any) => new Date(b.insertDateTime).getTime() - new Date(a.insertDateTime).getTime())}
                            columns={columns}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </Card>
                {/* } */}
            </> :
                <ViewKycData setShowKycData={setShowKycData} kycData={kycData.find(x => x.id == currentlyViewing)} />
            }
        </div>
    )
}