import React from "react";

interface ChevronDoubleLeftProps {
    className?: string;
    stroke?: string;
    strokeWidth?: string;
}

const ChevronDoubleLeft: React.FC<ChevronDoubleLeftProps> = ({ className, stroke, strokeWidth }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            stroke={stroke ?? ""}
            strokeWidth={strokeWidth ?? ""} width="16" height="16" fill="#0A0A0A" className={`bi bi-chevron-double-left ${className || ""}`} viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
            <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
        </svg>
    );
};

export default ChevronDoubleLeft;