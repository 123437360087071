import { jwtDecode } from 'jwt-decode';
import { DecodedToken, SimpleDecodedToken } from '../types/auth.types';
import { tokenService } from './token.service';

export const userService = {
    decodeToken(token: string): DecodedToken {
        return jwtDecode<DecodedToken>(token);
    },


    simplifyDecodedToken(decoded: DecodedToken): SimpleDecodedToken {
        return {
            company: decoded.CompanyName,
            audience: decoded.aud,
            expirationTime: decoded.exp,
            role: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            email: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
            name: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            userId: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
            clientId: decoded.ClientId,
            frSubscription: decoded.FrSubscription,
            ageSubscription: decoded.AgeSubscription,
            kycSubscription: decoded.KycSubscription,
            smileAndPaySubscription: decoded.SmileAndPaySubscription,
            issuer: decoded.iss
        };
    },

    getCurrentUser(): SimpleDecodedToken | null {
        const tokens = tokenService.getTokens();
        if (!tokens?.token) return null;

        try {
            const decoded = this.decodeToken(tokens.token);
            const simplified = this.simplifyDecodedToken(decoded);
            return simplified;
        } catch (error) {
            return null;
        }
    }
}; 