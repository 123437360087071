import { format } from "date-fns";
import { CalendarIcon, FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { cn, formatDateTime } from "../../lib/utils";
import { verificationService, type FormattedVerificationData } from "../../services/verification.service";
import { ContactModal } from "../ContactModal";
import { DataTable } from "../DataTable";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Card, CardContent } from "../ui/card";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { IntegrationSteps } from "./IntegrationSteps";

export const VerificationHistory = () => {
    const { user } = useUser();
    const [verificationData, setVerificationData] = useState<FormattedVerificationData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [date, setDate] = useState<Date>();
    const [clientType, setClientType] = useState<string>("");
    const [clientStatus, setClientStatus] = useState<string>("");
    const [answerFromIframe, setAnswerFromIframe] = useState();

    let expiresOn = "";
    let subscription = null;
    let ageShouldShow = false;

    if (user) {
        subscription = JSON.parse(user != null ? user.ageSubscription : "");

        if (subscription.IsActive.toLowerCase() == "true" && new Date(subscription.Expires) > new Date()) {
            ageShouldShow = true;
        }
        expiresOn = new Date(subscription.Expires).toLocaleString();
    }

    const columns = [
        {
            header: "Request ID",
            accessorKey: "request_id",
        },
        {
            header: "Age",
            accessorKey: "age",
            className: "w-20",
        },
        {
            header: "Gender",
            accessorKey: "gender",
            className: "w-24",
        },
        {
            header: "Status",
            accessorKey: "status",
        },
        {
            header: "Face Visible",
            accessorKey: "is_face_visible",
        },
        {
            header: "Spoof Detected",
            accessorKey: "is_spoof",
        },
        // {
        //     header: "Age Verification",
        //     accessorKey: "age_verification",
        // },
        {
            header: "Date",
            accessorKey: "created_at",
            className: "w-40",
        },
    ];

    // Reset filters
    const handleResetFilters = () => {
        setDate(undefined);
        setClientType("");
        setClientStatus("");
    };

    const fetchVerificationHistory = async () => {
        if (!user?.clientId) {
            setError("No client ID available");
            setIsLoading(false);
            return;
        }

        try {
            const data = await verificationService.getVerificationHistory(user.clientId, false);
            const formattedData = data.map(item => ({
                ...item,
                created_at: formatDateTime(item?.created_at || ""),
            }));

            const sortedData = formattedData.sort((a, b) => {
                const dateA = parseDate(a.created_at) as any;
                const dateB = parseDate(b.created_at) as any;

                return dateB - dateA; // Sort descending (latest first)
            });

            setVerificationData(sortedData);
            setError(null);
        } catch (err) {
            setError("Failed to fetch verification history");
            console.log("Error fetching verification history:", err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVerificationHistory();

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [answerFromIframe]);

    const handleMessage = (event) => {
        const iframe = document.getElementById('age-iframe') as HTMLIFrameElement;

        if (iframe && event.source === iframe.contentWindow) {
            if (event.data && event.data.response) {
                setAnswerFromIframe(event.data.response);
                // const dataToSend = {
                //     message: 'Hello from parent!',
                //     someValue: 42
                // };
                //iframe.contentWindow.postMessage(dataToSend, '*');
            }
        }
    };

    function parseDate(dateString: string) {
        const [day, month, yearTime] = dateString.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute] = time.split(':');

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    return (
        <div className="space-y-4 fade-in">
            <div className="d-flex flex-column flex-sm-row gap-4 justify-between">
                <h2 className="text-3xl font-bold tracking-tight">
                    {subscription.Type == "Demo" ? "Age Verification Demo" : "Age Verification - " + subscription.Type}
                </h2>
                <div className="d-flex align-items-center">
                    {!ageShouldShow &&
                        <h1 className="font-bold mr-2">Expired On:</h1>
                    }
                    {ageShouldShow &&
                        <h1 className="font-bold mr-2">Expires On:</h1>
                    }
                    {expiresOn.split(",")[0]}
                </div>
            </div>
            <div className="rounded-xl bg-card text-card-foreground"><div className="p-0 border rounded-lg shadow-none"></div></div>
            {ageShouldShow && subscription.Type == "Demo" &&
                <iframe id="age-iframe" src={`https://demo.voltox.ai/landing.html?data=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100vh" }} allow="camera" allowFullScreen>
                </iframe>
                // <iframe id="age-iframe" src={`https://127.0.0.1:5502/demo-age-verification/public/landing.html?data=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100vh" }} allow="camera" allowFullScreen>
                // </iframe>
            }
            {(ageShouldShow && subscription.Type != "Demo") &&
                <IntegrationSteps type="AgeVerification" />
            }
            {!ageShouldShow && (subscription.IsActive.toLowerCase() != "true" || new Date(subscription.Expires) < new Date()) &&
                <div className="text-center">
                    <h1 className="text-center mt-5 mb-4">
                        Seems like your subscription has expired or has been deactivated.
                        <br /><br />Please contact support below.
                    </h1>
                    <ContactModal user={user} type={"AGE"} buttonText={"Request Access"} />
                </div>
            }
            <br />
            <br />
            <br />

            {ageShouldShow &&
                <div className="flex items-center justify-between">
                    <h2 className="text-3xl font-bold tracking-tight">
                        Age Verification History
                    </h2>
                </div>
            }

            {ageShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                    </CardContent>
                </Card>
            }

            {ageShouldShow &&
                <div className="hidden items-center gap-2 p-4 bg-white rounded-lg border">
                    <div className="flex items-center gap-2">
                        <FilterIcon className="h-5 w-5 text-gray-500" />
                        <span className="text-sm font-medium">Filter By</span>
                    </div>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                className={cn(
                                    "justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >

                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date ? format(date as Date, "dd/MM/yyyy") : "Date"}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={date}
                                onSelect={setDate}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>

                    {/* Client Type Filter */}
                    <Select value={clientType} onValueChange={setClientType}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Client Type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="individual">Individual</SelectItem>
                            <SelectItem value="business">Business</SelectItem>
                            <SelectItem value="enterprise">Enterprise</SelectItem>
                        </SelectContent>
                    </Select>

                    {/* Client Status Filter */}
                    <Select value={clientStatus} onValueChange={setClientStatus}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Client Status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="pending">Pending</SelectItem>
                            <SelectItem value="inactive">Inactive</SelectItem>
                        </SelectContent>
                    </Select>

                    {/* Reset Filter Button */}
                    <Button
                        variant="ghost"
                        onClick={handleResetFilters}
                        className="ml-auto text-red-600 hover:text-red-700 hover:bg-red-50"
                    >
                        Reset Filter
                    </Button>
                </div>
            }
            {ageShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                        {error ? (
                            <div className="text-center text-red-500 p-4">{error}</div>
                        ) : verificationData.length === 0 && !isLoading ? (
                            <div className="text-center text-gray-500 p-4">No verification history found</div>
                        ) : (
                            <DataTable
                                data={verificationData}
                                columns={columns}
                                isLoading={isLoading}
                            />
                        )}
                    </CardContent>
                </Card>
            }
        </div>
    );
}; 