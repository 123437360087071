import React from "react";

interface CaretLeftProps {
    className?: string;
    stroke?: string;
    strokeWidth?: string;
    width: string;
    height: string;
}

const CaretLeft: React.FC<CaretLeftProps> = ({ className, stroke, strokeWidth, width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" stroke={stroke ?? ""}
            strokeWidth={strokeWidth ?? ""} className={`bi bi-chevron-left ${className || ""}`} viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
        </svg>
    );
};

export default CaretLeft;