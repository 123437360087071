import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import DashboardLogo from "../assets/voltox-dashboard-logo.png";
import { UserProfileDropdown } from '../components/UserProfileDropdown';
import AgeVerificationIcon from '../icons/AgeVerification';
import FaceRecognitionIcon from '../icons/FaceRecognitionIcon';
import KYCVerificationIcon from '../icons/KYCVerificationIcon';
import SmileAndPayIcon from '../icons/SmileAndPayIcon';
import { useApiService } from '../services/api.service';

interface Subscription {
    name: string;
    subscriptionType: string;
    validUntil: string;
    hasActiveSubscription: boolean;
}

interface IFormInput {
    id: number | undefined;
    name: string;
    companyName: string;
    email: string;
    password?: string;
    clientId?: string;
    frSubscription: Subscription;
    ageSubscription: Subscription;
    kycSubscription: Subscription;
    smileAndPaySubscription: Subscription;
    allowedDomains: string;
    isConfirmed: boolean;
}

export const Admin = () => {
    const { get, post, del } = useApiService();
    const { register, handleSubmit, reset } = useForm<IFormInput>();
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({}); // To track validation errors
    const [editingUser, setEditingUser] = useState<IFormInput | undefined>();
    const [totalAgeRequests, setTotalAgeRequests] = useState<number>(0);
    const [totalFrRequests, setTotalFrRequests] = useState<number>(0);
    const [totalKycRequests, setTotalKycRequests] = useState<number>(0);
    const [totalSmilePayRequests, setTotalSmilePayRequests] = useState<number>(0);

    const onSubmit: SubmitHandler<IFormInput> = async data => {
        const errors: { [key: string]: string } = {};

        if (!data.name || data.name.trim() === "") {
            errors.name = "Name is required.";
        }

        if (!data.companyName || data.companyName.trim() === "") {
            errors.companyName = "Company Name is required.";
        }

        if (!data.email || data.email.trim() === "") {
            errors.email = "Email is required.";
        }

        if (!data.frSubscription.subscriptionType || data.frSubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Subscription Type is required.";
        }

        if (!data.ageSubscription.subscriptionType || data.ageSubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Subscription Type is required.";
        }

        if (!data.kycSubscription.subscriptionType || data.kycSubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Subscription Type is required.";
        }

        if (!data.smileAndPaySubscription.subscriptionType || data.smileAndPaySubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Smile & Pay Type is required.";
        }

        if (!data.frSubscription.validUntil || data.frSubscription.validUntil.trim() === "") {
            errors.validUntil = "Date is required.";
        }

        if (!data.ageSubscription.validUntil || data.ageSubscription.validUntil.trim() === "") {
            errors.validUntil = "Date is required.";
        }

        if (!data.kycSubscription.validUntil || data.kycSubscription.validUntil.trim() === "") {
            errors.validUntil = "Date is required.";
        }

        if (!data.smileAndPaySubscription.validUntil || data.smileAndPaySubscription.validUntil.trim() === "") {
            errors.validUntil = "Smile & Pay is required.";
        }

        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return; // Do not proceed if there are validation errors
        }

        setFieldErrors({}); // Clear errors if validation passes

        const response = await post("/api/User/EditUser", data) as any;

        if (!response?.flag) {
            toast.success(response.message);
            throw new Error(response.message);
        }

        toast.success("Successfully edited!");
        fetchData();
        cancelEdit();
    }

    const fetchData = async () => {
        try {
            const response = await get("/api/User/GetUsers", undefined, true) as any;
            if (!response?.flag) {
                throw new Error(response?.message);
            }

            setError(null);
            setUsers(response?.data);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const edit = (user: IFormInput) => {
        fetchNumberOfRequests(user.clientId.toLocaleLowerCase(), "age").then((response) => response.json()).then((data) => { setTotalAgeRequests(data.length > 0 ? data.length : 0) });
        fetchNumberOfRequests(user.clientId.toLocaleLowerCase(), "fr").then((response) => response.json()).then((data) => { setTotalFrRequests(data.length > 0 ? data.length : 0) });
        fetchNumberOfRequests(user.clientId.toLocaleLowerCase(), "kyc").then((response) => response.json()).then((data) => { setTotalKycRequests(data.length > 0 ? data.filter(item => item.kyc !== null).length : 0) });
        fetchNumberOfRequests(user.clientId.toLocaleLowerCase(), "smileAndPay").then((response) => response.json()).then((data) => { setTotalSmilePayRequests(data.length > 0 ? data.length : 0) });

        window.scrollTo(0, 0)

        if (user.frSubscription?.validUntil) {
            const datePart = user.frSubscription.validUntil.split("T")[0];
            user.frSubscription.validUntil = datePart;
        }

        if (user.ageSubscription?.validUntil) {
            const datePart = user.ageSubscription.validUntil.split("T")[0];
            user.ageSubscription.validUntil = datePart;
        }

        if (user.kycSubscription?.validUntil) {
            const datePart = user.kycSubscription.validUntil.split("T")[0];
            user.kycSubscription.validUntil = datePart;
        }

        if (user.smileAndPaySubscription?.validUntil) {
            const datePart = user.smileAndPaySubscription.validUntil.split("T")[0];
            user.smileAndPaySubscription.validUntil = datePart;
        }

        setEditingUser(user);
        setShowEdit(true);
        reset(user);
    }

    const cancelEdit = () => {
        setEditingUser(undefined);
        setShowEdit(false);
        reset();
    }

    const confirmEmail = async () => {
        try {
            const response = await post(`/api/User/ConfirmEmailByAdmin?id=${editingUser?.id}`) as any;

            if (!response?.flag) {
                toast.error(response.message);
                throw new Error(response.message);
            }

            const message = `Successfully confirmed user email!`;
            toast.success(message);
            cancelEdit();

            await fetchData();
        }
        catch (error) {
            console.error("Error suspending subscription:", error);
        }
    }

    const deleteUser = async (id: number) => {
        if (window.confirm("Are you sure you want to delete the user " + users.find(x => x.id == id).name + " ?") == true) {
            const response = await post("/api/User/DeleteUser", id) as any;

            if (!response?.flag) {
                toast.success(response.message);
                throw new Error(response.message);
            }

            toast.success("Successfully deleted!");
            setUsers(users.filter(user => user.id !== id))
        }
    }

    const suspendSubscription = async (name: string) => {
        try {
            const response = await post("/api/User/SuspendSubscription", { userId: editingUser?.id, name: name }) as any;

            if (!response?.flag) {
                toast.error(response.message);
                throw new Error(response.message);
            }

            const type = () => {
                if (name === "FaceRecognition") {
                    return editingUser?.frSubscription.hasActiveSubscription ? "suspended" : "activated";
                }
                else if (name === "AgeVerification") {
                    return editingUser?.ageSubscription.hasActiveSubscription ? "suspended" : "activated";
                }
                else if (name === "Kyc") {
                    return editingUser?.kycSubscription.hasActiveSubscription ? "suspended" : "activated";
                }
                else if (name === "SmileAndPay") {
                    return editingUser?.smileAndPaySubscription.hasActiveSubscription ? "suspended" : "activated";
                }

                return "";
            }

            const message = `Successfully ${type()} ${name} subscription!`;
            toast.success(message);

            setEditingUser(prevUser => {
                const updatedUser = { ...prevUser };
                if (name === "FaceRecognition") {
                    updatedUser.frSubscription = {
                        ...updatedUser.frSubscription,
                        hasActiveSubscription: !updatedUser.frSubscription.hasActiveSubscription
                    };
                }
                else if (name === "AgeVerification") {
                    updatedUser.ageSubscription = {
                        ...updatedUser.ageSubscription,
                        hasActiveSubscription: !updatedUser.ageSubscription.hasActiveSubscription
                    };
                }
                else if (name === "Kyc") {
                    updatedUser.kycSubscription = {
                        ...updatedUser.kycSubscription,
                        hasActiveSubscription: !updatedUser.kycSubscription.hasActiveSubscription
                    };
                }
                else if (name === "SmileAndPay") {
                    updatedUser.smileAndPaySubscription = {
                        ...updatedUser.smileAndPaySubscription,
                        hasActiveSubscription: !updatedUser.smileAndPaySubscription.hasActiveSubscription
                    };
                }
                return updatedUser;
            });

            await fetchData();
        } catch (error) {
            console.error("Error suspending subscription:", error);
        }
    };

    const fetchNumberOfRequests = async (clientId: string, type: string) => {
        let baseUrl = "https://api.voltox.ai";
        let endPoint = "/get-client-data";
        let typeWithKey = "";
        if (type == "fr") {
            typeWithKey += "&key=face_search"
        }
        else if (type == "kyc") {
            typeWithKey += "&key=kyc"
        }
        else if (type == "smileAndPay") {
            typeWithKey += "&key=smilenpay"
        }

        return await fetch(`${baseUrl}${endPoint}?client_id=${encodeURIComponent(clientId)}${typeWithKey}`, {
            method: "GET",
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <nav className="bg-white">
                <div className="d-flex h-16">
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "95%", margin: "auto" }}>
                        <img src={DashboardLogo} alt="logo" width={150} />
                        <h1 className='font-bold tracking-tight' style={{ fontSize: "18px", color: "#1b5257" }}>Admin Dashboard</h1>
                        <UserProfileDropdown />
                    </div>
                </div>
            </nav>
            <div style={{ backgroundColor: "#f5f6fa", height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <h2 className="text-3xl font-bold tracking-tight">Client Management</h2>
                        {showEdit &&
                            <div className='col-md-12 mt-5'>
                                <form onSubmit={handleSubmit(onSubmit)} className="">
                                    <div className="row gy-3 align-items-end">
                                        <div className="col-md-3">
                                            <label htmlFor="name" className="form-label">Name <span className='red'>*</span></label>
                                            <input type='text' id='name'
                                                className={fieldErrors.name ? 'form-control is-invalid' : 'form-control'}
                                                {...register("name")}
                                            />
                                            {fieldErrors.name && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.name}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="companyName" className="form-label">Company Name <span className='red'>*</span></label>
                                            <input type='text' id='companyName' className={fieldErrors.companyName ? 'form-control is-invalid' : 'form-control'} {...register("companyName")} />
                                            {fieldErrors.companyName && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.companyName}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="email" className="form-label">Email <span className='red'>*</span></label>
                                            <input type='text' id='email' className={fieldErrors.email ? 'form-control is-invalid' : 'form-control'} {...register("email")} />
                                            {fieldErrors.email && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type='password' id='password' className='form-control' {...register("password")} />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="frSubscriptionType" className="form-label">Face Recognition Subscription Type <span className='red'>*</span></label>
                                            <select
                                                id="frSubscriptionType"
                                                className={fieldErrors.frSubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("frSubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.frSubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.frSubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="frValidUntil" className="form-label">Face Recognition Valid Until <span className='red'>*</span></label>
                                            <input type='date' id='frValidUntil' className={fieldErrors.frValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("frSubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.frValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.frValidUntil}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="ageSubscriptionType" className="form-label">Age Verification Subscription Type <span className='red'>*</span></label>
                                            <select
                                                id="ageSubscriptionType"
                                                className={fieldErrors.ageSubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("ageSubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.ageSubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.ageSubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="ageValidUntil" className="form-label">Age Verification Valid Until <span className='red'>*</span></label>
                                            <input type='date' id='ageValidUntil' className={fieldErrors.ageValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("ageSubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.ageValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.ageValidUntil}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="kycSubscriptionType" className="form-label">Kyc Verification Subscription Type <span className='red'>*</span></label>
                                            <select
                                                id="kycSubscriptionType"
                                                className={fieldErrors.kycSubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("kycSubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.kycSubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.kycSubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="kycValidUntil" className="form-label">Kyc Verification Valid Until <span className='red'>*</span></label>
                                            <input type='date' id='kycValidUntil' className={fieldErrors.kycValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("kycSubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.kycValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.kycValidUntil}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="smilePaySubscriptionType" className="form-label">Smile & Pay Subscription Type <span className='red'>*</span></label>
                                            <select
                                                id="smilePaySubscriptionType"
                                                className={fieldErrors.smilePaySubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("smileAndPaySubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.smilePaySubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.smilePaySubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="smilePayValidUntil" className="form-label">Smile & Pay Valid Until <span className='red'>*</span></label>
                                            <input type='date' id='smilePayValidUntil' className={fieldErrors.smilePayValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("smileAndPaySubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.smilePayValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.smilePayValidUntil}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="domainsAllowed" className="form-label">Domains Allowed - Use comma (,) between domains</label>
                                            <input type='text' id='domainsAllowed' placeholder='Example: https://clientweb1.com,https://clientweb2.com' className='form-control' {...register("allowedDomains")} />
                                        </div>
                                        <div className="col-md-3 mt-4 mb-2 d-flex align-items-center">
                                            <FaceRecognitionIcon /><span className='ms-2'>Total Face Recognition Requests: {totalFrRequests}</span>
                                        </div>
                                        <div className="col-md-3 mt-4 mb-2 d-flex align-items-center">
                                            <AgeVerificationIcon /><span className='ms-2'>Total Age Verification Requests: {totalAgeRequests}</span>
                                        </div>
                                        <div className="col-md-3 mt-4 mb-2 d-flex align-items-center">
                                            <KYCVerificationIcon /><span className='ms-2'>Total KYC Requests: {totalKycRequests}</span>
                                        </div>
                                        <div className="col-md-3 mt-4 mb-2 d-flex align-items-center">
                                            <SmileAndPayIcon /><span className='ms-2'>Total Smile & Pay Requests: {totalSmilePayRequests}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-4 d-flex align-items-end">
                                        <button type='button' onClick={() => suspendSubscription("FaceRecognition")} className={editingUser?.frSubscription?.hasActiveSubscription ? 'd-flex align-items-center col-auto btn btn-warning' : 'd-flex align-items-center col-auto btn btn-info'}><FaceRecognitionIcon /> <span className='ps-2'>{editingUser?.frSubscription?.hasActiveSubscription ? 'Suspend ' : 'Activate '} FR</span></button>
                                        <button type='button' onClick={() => suspendSubscription("AgeVerification")} className={editingUser?.ageSubscription?.hasActiveSubscription ? 'd-flex align-items-center col-auto btn ml-3 btn-warning' : 'd-flex align-items-center col-auto btn ml-3 btn-info'}><AgeVerificationIcon /> <span className='ps-2'>{editingUser?.ageSubscription?.hasActiveSubscription ? 'Suspend ' : 'Activate '} AV</span></button>
                                        <button type='button' onClick={() => suspendSubscription("Kyc")} className={editingUser?.kycSubscription?.hasActiveSubscription ? 'd-flex align-items-center col-auto btn ml-3 btn-warning' : 'd-flex align-items-center col-auto btn ml-3 btn-info'}><KYCVerificationIcon /> <span className='ps-2'>{editingUser?.kycSubscription?.hasActiveSubscription ? 'Suspend ' : 'Activate '} KYC</span></button>
                                        <button type='button' onClick={() => suspendSubscription("SmileAndPay")} className={editingUser?.smileAndPaySubscription?.hasActiveSubscription ? 'd-flex align-items-center col-auto btn ml-3 btn-warning' : 'd-flex align-items-center col-auto btn ml-3 btn-info'}><SmileAndPayIcon /> <span className='ps-2'>{editingUser?.smileAndPaySubscription?.hasActiveSubscription ? 'Suspend ' : 'Activate '} Smile & Pay</span></button>
                                        {!editingUser.isConfirmed && <button type='button' onClick={confirmEmail} className='col-auto btn btn-info ml-3' style={{ width: "150px" }}>Confirm Email</button>}
                                        <button type='button' onClick={cancelEdit} className='col-auto btn btn-danger ml-3' style={{ width: "150px" }}>Cancel</button>
                                        <button type='submit' className='col-auto btn btn-success ml-3' style={{ width: "150px" }}>Save</button>
                                    </div>
                                </form>
                            </div>
                        }

                        <div className="table-responsive mt-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='p-4' scope="col">Name</th>
                                        <th className='p-4' scope="col">Company Name</th>
                                        <th className='p-4' scope="col">Email</th>
                                        <th className='p-4' scope="col">Subscription Plan</th>
                                        <th className='p-4' scope="col">FR Valid Until</th>
                                        <th className='p-4' scope="col">AGE Valid Until</th>
                                        <th className='p-4' scope="col">KYC Valid Until</th>
                                        <th className='p-4' scope="col">Smile & Pay Valid Until</th>
                                        <th className='p-4' scope="col">Register Date</th>
                                        <th className='p-4' scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {error != null && <tr className='text-center'><td colSpan={12}>{error}</td></tr>}
                                    {users
                                        .sort((a: any, b: any) => new Date(b.dateRegistered).getTime() - new Date(a.dateRegistered).getTime())
                                        .map((x: any) => {
                                            return (
                                                <tr key={x.id}>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>{x.name}</td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>{x.companyName}</td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>{x.email}</td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        {
                                                            x.frSubscription?.name + " - " + x.frSubscription?.subscriptionType
                                                        }
                                                        <br />
                                                        {
                                                            x.ageSubscription?.name + " - " + x.ageSubscription?.subscriptionType
                                                        }
                                                        <br />
                                                        {
                                                            x.kycSubscription?.name + " - " + x.kycSubscription?.subscriptionType
                                                        }
                                                        <br />
                                                        {
                                                            x.smileAndPaySubscription?.name + " - " + x.smileAndPaySubscription?.subscriptionType
                                                        }
                                                    </td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        {
                                                            x.frSubscription ? new Date(x.frSubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                        }
                                                    </td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        {
                                                            x.ageSubscription ? new Date(x.ageSubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                        }
                                                    </td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        {
                                                            x.kycSubscription ? new Date(x.kycSubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                        }
                                                    </td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        {
                                                            x.smileAndPaySubscription ? new Date(x.smileAndPaySubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                        }
                                                    </td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>{new Date(x.dateRegistered.split("T")[0]).toLocaleDateString("de-DE")}</td>
                                                    <td className={`p-4 ${x.isConfirmed ? "tableGreenBg" : "tableRedBg"}`}>
                                                        <div style={{ width: "90px" }}>
                                                            <button onClick={() => edit(x)}><i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} className="bi bi-pencil-square pr-5"></i></button>
                                                            <button onClick={() => deleteUser(x.id)}><i style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="bi bi-trash3"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}; 