export const smileandpayService = {
    async getHistory(clientId: string): Promise<any> {
        try {
            let response: any;

            response = await fetch(`https://api.voltox.ai/get-client-data?client_id=${clientId}&key=smilenpay`, {
                method: "GET",
            });

            response = await response.json();
            return response;
        } catch (error) {
            console.log('Error fetching kyc history:', error);
            throw new Error('Failed to fetch kyc history');
        }
    }
}