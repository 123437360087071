import { ToastBar, Toaster, toast } from 'react-hot-toast';

export const ToastProvider = () => {
    return (
        <Toaster
            position="top-right"
            toastOptions={{
                duration: 4000,
            }}
        >
            {(t) => {
                return (
                    <div
                        className="w-auto cursor-pointer"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <ToastBar toast={t} />
                    </div>
                );
            }}
        </Toaster>
    );
}; 