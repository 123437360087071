import { DashboardOverview } from "../components/dashboard-sections/DashboardOverview";
import { FaceRecognition } from "../components/dashboard-sections/FaceRecognition";
import { KYCVerification } from "../components/dashboard-sections/KYCVerification";
import { Settings as SettingsComponent } from "../components/dashboard-sections/Settings";
import { SmileAndPay } from "../components/dashboard-sections/SmileAndPay";
import { VerificationHistory } from "../components/dashboard-sections/VerificationHistory";
import AgeVerification from "../icons/AgeVerification";
import DashboardIcon from "../icons/DashboardIcon";
import FaceRecognitionIcon from "../icons/FaceRecognitionIcon";
import KYCVerificationIcon from "../icons/KYCVerificationIcon";
import SettingsIcon from "../icons/SettingsIcon";
import SmileAndPayIcon from "../icons/SmileAndPayIcon";

export const routes = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: DashboardIcon,
        component: DashboardOverview,
    },
    {
        id: 'age-verification',
        title: 'Age Verification',
        icon: AgeVerification,
        component: VerificationHistory,
    },
    {
        id: 'face-recognition',
        title: 'Face Recognition',
        icon: FaceRecognitionIcon,
        component: FaceRecognition,
    },
    {
        id: 'kyc-verification',
        title: 'KYC Verification',
        icon: KYCVerificationIcon,
        component: KYCVerification,
    },
    {
        id: 'smile-pay',
        title: 'Smile and Pay',
        icon: SmileAndPayIcon,
        component: SmileAndPay,
    },
    {
        id: 'settings',
        title: 'Settings',
        icon: SettingsIcon,
        component: SettingsComponent,
    },
] as const;

export type RouteId = typeof routes[number]['id']; 